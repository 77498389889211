// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WNpmHjZzu", "aCwkhUHb9"];

const serializationHash = "framer-gG2wl"

const variantClassNames = {aCwkhUHb9: "framer-v-11f5lcm", WNpmHjZzu: "framer-v-1ozwq7v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.7, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Type 1": "WNpmHjZzu", "Type 2": "aCwkhUHb9"}

const getProps = ({height, id, image1, image2, width, ...props}) => { return {...props, QwRYQ57Ld: image2 ?? props.QwRYQ57Ld ?? {src: "https://framerusercontent.com/images/p4571B5DdxYNDTcphjDgbmxxw.svg"}, rRO3srHf8: image1 ?? props.rRO3srHf8 ?? {src: "https://framerusercontent.com/images/t8DOCeMeXqJps8GGw0F9mSVLgY.svg"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "WNpmHjZzu"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image1?: {src: string; srcSet?: string};image2?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, rRO3srHf8, QwRYQ57Ld, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WNpmHjZzu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1a2vbif = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 1500)
})

useOnVariantChange(baseVariant, {default: onAppear1a2vbif})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ozwq7v", className, classNames)} data-framer-name={"Type 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"WNpmHjZzu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({aCwkhUHb9: {"data-framer-name": "Type 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 717, intrinsicWidth: 610, pixelHeight: 717, pixelWidth: 610, sizes: "min(491px, 100vw)", ...toResponsiveImage(QwRYQ57Ld)}} className={"framer-15nk8fb"} data-framer-name={"Back"} layoutDependency={layoutDependency} layoutId={"mTjM_24yB"}/><Image background={{alt: "", fit: "fill", intrinsicHeight: 717, intrinsicWidth: 609, pixelHeight: 717, pixelWidth: 609, sizes: "min(491px, 100vw)", ...toResponsiveImage(rRO3srHf8)}} className={"framer-re4kro"} data-framer-name={"Front"} layoutDependency={layoutDependency} layoutId={"ryXWIBu58"} style={{opacity: 1}} variants={{aCwkhUHb9: {opacity: 0}}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-gG2wl[data-border=\"true\"]::after, .framer-gG2wl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gG2wl.framer-mhz45q, .framer-gG2wl .framer-mhz45q { display: block; }", ".framer-gG2wl.framer-1ozwq7v { height: 620px; overflow: hidden; position: relative; width: 491px; }", ".framer-gG2wl .framer-15nk8fb, .framer-gG2wl .framer-re4kro { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 620
 * @framerIntrinsicWidth 491
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"aCwkhUHb9":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"rRO3srHf8":"image1","QwRYQ57Ld":"image2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerbgMWYqqIz: React.ComponentType<Props> = withCSS(Component, css, "framer-gG2wl") as typeof Component;
export default FramerbgMWYqqIz;

FramerbgMWYqqIz.displayName = "Background/About Image BG";

FramerbgMWYqqIz.defaultProps = {height: 620, width: 491};

addPropertyControls(FramerbgMWYqqIz, {variant: {options: ["WNpmHjZzu", "aCwkhUHb9"], optionTitles: ["Type 1", "Type 2"], title: "Variant", type: ControlType.Enum}, rRO3srHf8: {__defaultAssetReference: "data:framer/asset-reference,t8DOCeMeXqJps8GGw0F9mSVLgY.svg?originalFilename=2-about-bg.svg&preferredSize=auto", title: "Image 1", type: ControlType.ResponsiveImage}, QwRYQ57Ld: {__defaultAssetReference: "data:framer/asset-reference,p4571B5DdxYNDTcphjDgbmxxw.svg?originalFilename=1-about-bg.svg&preferredSize=auto", title: "Image 2", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerbgMWYqqIz, [])